.container {
  height: 100vh;
  width: 100vw;
  background-color: #eee;
  color: #787878;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.title {
  text-align: left;
  padding-top: 20vh;
}
